

import MainRoutes from './Routes';

function App() { 
  return (
    <div className="App">          
        <MainRoutes />
    </div>
  );
}

export default App;
