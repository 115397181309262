import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Link, Outlet, NavLink } from "react-router-dom";
import AppDrawer from '../Sidebar/SideBarAdminD';
import SidebarUser from '../Sidebar/SidebarUser';
import SidebarDealership from '../Sidebar/SideBarDealership';
import ApplicationStore from '../../../utils/localStorageUtil';
import MainContent from './MainD';
import ResponsiveAppBar from '../Sidebar/AppBarMI';
import Container from '@mui/material/Container';
// import "./Home.css";



const HomeAdmin = ({ window }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const userType = ApplicationStore().getStorage('user_type');
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ResponsiveAppBar handleDrawerToggle={handleDrawerToggle} />
      <div >
        {/* Conditional rendering for Sidebar based on userType */}
        {userType === "admin" ? (
          <Box

            className='scrollableNew'
            component="nav"
            sx={{
              width: { xs: '100%', sm: 240 }, // Full width on extra-small screens (mobile), fixed 240px on larger screens
              flexShrink: { sm: 0 },
            }}
          >
            {/* Admin Sidebar */}
            <AppDrawer
              mobileOpen={mobileOpen}
              handleDrawerClose={() => setMobileOpen(false)}
              handleDrawerTransitionEnd={handleDrawerTransitionEnd}
              sx={{
                width: { sm: 240 },
                flexShrink: 0,
                position: { sm: 'fixed' }, // Fixed on larger screens
                height: '100vh',

              }}
            />
          </Box>
        ) : userType == "dealership" ?
          (
            <Box
              component="nav"
              sx={{
                width: { xs: '100%', sm: 240 },
                flexShrink: { sm: 0 },
              }}
            >
              {/* Dealership Sidebar */}
              <SidebarDealership
                mobileOpen={mobileOpen}
                handleDrawerClose={() => setMobileOpen(false)}
                handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                sx={{
                  width: { sm: 240 },
                  flexShrink: 0,
                  position: { sm: 'fixed' }, // Fixed on larger screens
                  height: '100vh',
                }}
              />
            </Box>
          ) : (
            <Box
              component="nav"
              sx={{
                width: { xs: '100%', sm: 240 },
                flexShrink: { sm: 0 },
              }}
            >
              {/* User Sidebar */}
              <SidebarUser
                mobileOpen={mobileOpen}
                handleDrawerClose={() => setMobileOpen(false)}
                handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                sx={{
                  width: { sm: 240 },
                  flexShrink: 0,
                  position: { sm: 'fixed' }, // Fixed on larger screens
                  height: '100vh',
                }}
              />
            </Box>
          )}
      </div>


      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // position: { sm: 'absolute' }, // Fixed on larger screens
          // marginLeft: { sm: 240 }, // Push content to the right of the sidebar on larger screens
          padding: { xs: 1, sm: 3 }, // Less padding on mobile, more on larger screens
          width: { xs: '100%', sm: `calc(100% - 240px)` }, // Full width on mobile, adjusted on larger screens
        }}
      >
        <Container maxWidth="xl" className='scrollable'>
          <Outlet />
          {/* <MainContent /> */}
        </Container>
      </Box>
    </Box>
  );
};

HomeAdmin.propTypes = {
  window: PropTypes.func,
};

export default HomeAdmin;
