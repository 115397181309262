import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, TextField, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import axios from "../../../api/axios";
import ApplicationStore from "../../../utils/localStorageUtil";
const URL = './user/createUser';

const UserModelComponent = ({ open, setOpen, isAddButton, rowData, setRefreshData, trackno }) => {
    //basic information
    const [id, setId] = useState('');
    const userType = ApplicationStore().getStorage('user_type');
    const [user_email, setUser_email] = useState('');
    const [user_name, setUser_name] = useState('');
    const [user_dealership, setUser_dealership] = useState('');
    const [user_password, setUser_password] = useState('');
    const [alertOpen, setAlertopen] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [dealershipArray, setDealershipArray] = useState([]);
    const [error, setError] = useState(false);
    const user = ApplicationStore().getStorage('user_email');
    const dealership = ApplicationStore().getStorage('dealership');


    const handleClick = () => {
        setAlertopen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertopen(false);
    };

    const serviceMethod = async (mainURL, method, data, handleSuccess, handleException) => {
        try {
            const response = await axios.post(mainURL, data);
            return handleSuccess(response.data);
        } catch (err) {
            if (!err?.response) {
                console.log("No server response");
            } else {
                return handleException(err?.response.data);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = "POST";
        if (!user_email || !user_name || !user_password || !user_dealership) {
            alert("Please fill out all the fields."); // Replace with your preferred error handling
            return;
        }
        if (isAddButton) {
            const data = { user_email, user_name, user_password, dealership: user_dealership };
            const mainURL = URL;
            serviceMethod(mainURL, method, data, handleSuccess, handleException);
        } else {
            const data = { user_email, user_name, user_password, dealership: user_dealership };
            const mainURL = URL + '/' + data.id + '/update';
            serviceMethod(mainURL, method, data, handleSuccess, handleException);
        }
    };

    useEffect(() => {
        setOpen(open);
        loadDealership();
        if (isAddButton) {
            setUser_email("");
            setUser_name("");
            setUser_password("");
            setUser_dealership("");
            setAlertopen(false);
        } else {
            loadData(rowData);
        }
        if (userType === 'dealership') {
            setUser_dealership(dealership);
        }

    }, [rowData, isAddButton, userType]);

    const loadDealership = async () => {
        try {
            const URL = "./dealership";
            const response = await axios.get(URL);

            if (response.data.status === 401) {
                setDealershipArray(""); // Keep dummy data in case of unauthorized response
            } else {
                const responseData = response.data.data;
                // const dataWithIndex = response.data.data.map((item, index) => ({
                //     ...item,
                //     slNo: index + 1, // Assign sequential SL No starting from 1
                // })) || "";
                setDealershipArray(responseData);
            }
        } catch (err) {
            console.log("Error fetching data:", err);
            // Use dummy data if request fails
            setDealershipArray('');
        }
    };

    const loadData = () => {
        console.log(rowData);
        setId(rowData.id);
        setUser_email(rowData.user_email);
        setUser_name(rowData.user_name);
    };

    const handleSuccess = (data) => {
        setSeverity("success");
        setMessage("Data Added Successfully");
        setAlertopen(true);
        setTimeout(() => {
            setOpen(false);
            setRefreshData((oldValue) => !oldValue);
        }, 1000); // Matches autoHideDuration
    };

    const handleException = (data) => {
        setSeverity("error");
        setMessage(data.data);
        setAlertopen(true);
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        setUser_email(email);

        // Check if the email includes ".com"
        if (email.includes(".com") && /\S+@\S+\.\S+/.test(email)) {
            setError(false); // Valid email
        } else {
            setError(true); // Invalid email
        }
    };

    const generateAutoPassword = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters[randomIndex];
        }
        // console.log("Generated Password:", password);
        setUser_password(password);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '100%' } }}
            open={open}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        color: 'navy', // Text color (affects the outline for outlined buttons)
                        borderColor: 'navy', // Outline color
                        '&:hover': {
                            borderColor: 'darkblue', // Darken the outline on hover
                        },
                    }}>
                    {isAddButton ? "Add User" : "Edit User"}
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom sx={{ marginBottom: '1.0em' }}>
                                        Dealership
                                    </Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={user_dealership}
                                        disabled={userType === 'dealership'}
                                        onChange={(e) => setUser_dealership(e.target.value)}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Please Select Dealership</em>
                                        </MenuItem>
                                        {dealershipArray.map((dealership, index) => (
                                            <MenuItem key={index} value={dealership.id}>
                                                {dealership.accountName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Name
                                    </Typography>
                                    <TextField
                                        value={user_name}
                                        margin="dense"
                                        id="outlined-basic"
                                        variant="outlined"
                                        required
                                        onChange={(e) => { setUser_name(e.target.value) }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray', // Default border color
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray', // Remove hover border color
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray', // Remove focus border color
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Email
                                    </Typography>
                                    <TextField
                                        value={user_email}
                                        margin="dense"
                                        id="outlined-basic"
                                        variant="outlined"
                                        required
                                        type="email"
                                        onChange={handleEmailChange}
                                        error={error} // Set error state
                                        helperText={error ? "Please enter a valid email with '.com'" : ""}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Password
                                    </Typography>
                                    <TextField
                                        value={user_password}
                                        margin="dense"
                                        id="outlined-basic"
                                        variant="outlined"
                                        required
                                        onChange={(e) => { setUser_password(e.target.value) }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'lightgray',
                                                },
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ margin: '10px' }}>
                    <Button
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'navy',
                            borderColor: 'navy',
                            '&:hover': {
                                borderColor: 'darkblue',
                            },
                        }}
                        autoFocus
                        onClick={(e) => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        variant="contained"

                        sx={{
                            backgroundColor: '#0d2365',  // Change background color to navy blue
                            '&:hover': {
                                backgroundColor: '#0d2365',  // Darken the color on hover
                            },
                            borderRadius: '10px'
                        }}
                        type="submit"
                    >
                        {isAddButton ? "Save" : "Update"}
                    </Button>

                    <Button
                        size="large"
                        variant="contained"

                        sx={{
                            backgroundColor: '#0d2365',  // Change background color to navy blue
                            '&:hover': {
                                backgroundColor: '#0d2365',  // Darken the color on hover
                            },
                            borderRadius: '10px'
                        }}
                        type="button"
                        onClick={generateAutoPassword}
                    >
                        {"Autogenerate Password"}
                    </Button>

                </DialogActions>
                <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </form>
        </Dialog>
    );
}

export default UserModelComponent;
