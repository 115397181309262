
import { Link, Outlet } from "react-router-dom";
function HomeStudent(){

    return(
        <>
        <Outlet />
        </>
    )
};
export default HomeStudent;