import React from 'react';
import { Routes, Route , Navigate} from 'react-router-dom';
import Login from './pages/components/Login/Login';
import Registration from './pages/components/Login/Registration';
import ProtectedRoutes from './protectedRoutes';
import ChangePassword from './pages/components/Login/ChangePassword';

import AddItem from './pages/components/Admin/ManageItem/AddItem';
import ProtectedDoctorRoutes from './protectedDoctorRoutes';
import AddStudent from './pages/components/Admin/ManageStudent/AddStudent';
import ViewStudent from './pages/components/Admin/ManageStudent/ViewStudent';
import ViewItem from './pages/components/Admin/ManageItem/ViewItem';

import ViewRecharge from './pages/components/Admin/ManageRecharge/ViewRecharge';
import ViewOrders from './pages/components/Admin/ManageOrders/ViewOrders';
import ViewDetailOrder from './pages/components/Admin/ManageOrders/ViewDetailOrder';
import ViewPayment from './pages/components/Admin/ManagePayment/ViewPayment';
import Home from './pages/components/Main/pages/Home';
import Cart from './pages/components/Main/pages/Cart';
import ProductInfo from './pages/components/Main/pages/ProductInfo';
import Feedback from './pages/components/Main/pages/Feedback';
import Payment from './pages/components/Main/pages/Payment';
import Service from './pages/components/Main/pages/Service';
import Checkout from './pages/components/Main/pages/Checkout';
import ProductList from './pages/components/Main/pages/ProductList';
import HomeStudent from './pages/components/Home/HomeStudent';
import HomeAdmin from './pages/components/Home/AdminD';
import AddCompany from './pages/components/Admin/ManageCompany/AddCompany';
import ViewCompany from './pages/components/Admin/ManageCompany/ViewCompany';
import AddMedicine from './pages/components/Admin/Personal Information/AddPersonalInformation';
import ViewMedicine from './pages/components/Admin/ManageMedicine/ViewMedicine';
import AddPharmacy from './pages/components/Admin/ManagePharmacy/AddPharmacy';
import ViewPharmacy from './pages/components/Admin/ManagePharmacy/ViewPharmacy';
import AddDoctor from './pages/components/Admin/ManageDoctor/AddDoctor';
import ViewDoctor from './pages/components/Admin/ManageDoctor/ViewDoctor';
import AddCity from './pages/components/Admin/ManageAddress/ManageCity/AddCity';
import ViewCity from './pages/components/Admin/ManageAddress/ManageCity/ViewCity';
import AddState from './pages/components/Admin/ManageAddress/ManageState/AddState';
import ViewState from './pages/components/Admin/ManageAddress/ManageState/ViewState';
import AddDistrict from './pages/components/Admin/ManageAddress/ManageDistrict/AddDistrict';
import ViewDistrict from './pages/components/Admin/ManageAddress/ManageDistrict/ViewDistrict';
import ViewUser from './pages/components/Admin/ManageUser/ViewUser';
import ViewComplaint from './pages/components/Admin/ManageComplaint/ViewComplaint';
import StaffList from './pages/components/User/UserList';
import AddPersonalInformation from './pages/components/Admin/Personal Information/AddPersonalInformation';
import ViewDealership from './pages/components/Admin/Dealership/ViewDealership';
import AddDealership from './pages/components/Admin/Dealership/AddDealership';
import Dashboard from './pages/components/Admin/Dashboard/Dashboard';
import WarrantyProducts from './pages/components/Admin/ManageWarantyProducts/WarrantyProducts';
import UserList from './pages/components/User/UserList';
import BuildWarranty from './pages/components/BuildWarranty/BuildWarranty';
import ViewPendingWarranty from './pages/components/BuildWarranty/ViewPendingWarranty';
import ViewClosedWarranty from './pages/components/BuildWarranty/ViewClosedWarranty';
import AddCategory from './pages/components/Admin/Category/AddCategory';
import AddSubCategory from './pages/components/Admin/SubCategory/AddSubCategory';
import ViewSubCategory from './pages/components/Admin/SubCategory/ViewSubCategory';
import ViewCategory from './pages/components/Admin/Category/ViewCategory';
import AddwarrantyPro from './pages/components/Admin/WarrantyProtection/AddwarrantyPro';
import ViewwarrantyPro from './pages/components/Admin/WarrantyProtection/ViewwarrantyPro';
import ViewWarrantyProduct from './pages/components/Admin/ManageWarantyProducts/ViewWarrantyProduct';
import ForgotPassword from './pages/components/Login/ForgotPassword';
import ForgotChangePassword from './pages/components/Login/ForgotChangePassword';
import PDFgen from './pages/components/BuildWarranty/PDF';

const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path="/Login" element={<Login />} />
      <Route path="/Registration" element={<Registration />} />
      <Route path="/InitialPasswordChange" element={<ChangePassword />} />      
      <Route path="/ForgotPasswordRequest" element={<ForgotPassword />} />      
      <Route path="/ForgotPassword/:emailId" element={<ForgotChangePassword />} />      
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<HomeAdmin />}>
        <Route index element={<Navigate to="/Dashboard" />} />
          <Route path="/Dashboard" element={<Dashboard />} /> 
          <Route path="/A" element={<Dashboard />} />
          <Route path="/BuildWarranty" element={<BuildWarranty />} />
          <Route path="/PDFGen" element={<PDFgen />} />
          <Route path="/ViewPendingWarranty" element={<ViewPendingWarranty />} />
          <Route path="/ViewClosedWarranty" element={<ViewClosedWarranty />} />
          <Route path="/WarrantyProducts" element={<WarrantyProducts />} />
          <Route path="/Users" element={<UserList />} />   
          <Route path="/Personalinformation" element={<AddPersonalInformation />} />
          <Route path="/ViewWarrantyProduct" element={<ViewWarrantyProduct />} />
          <Route path="/ViewDealership" element={<ViewDealership />} />
          <Route path="/AddDealership" element={<AddDealership />} />
          <Route path="/ViewCompany" element={<ViewCompany />} />
          <Route path="/AddMedicine" element={<AddMedicine />} />
          <Route path="/ViewMedicine" element={<ViewMedicine />} />
          <Route path="/AddSubCategory" element={<AddSubCategory />} />
          <Route path="/AddCategory" element={<AddCategory />} />
          <Route path="/ViewCategory" element={<ViewCategory />} />
          <Route path="/ViewSubCategory" element={<ViewSubCategory />} />
          <Route path="/AddwarrantyPro" element={<AddwarrantyPro />} />
          <Route path="/ViewwarrantyPro" element={<ViewwarrantyPro />} />
          <Route path="/AddPharmacy" element={<AddPharmacy />} />
          <Route path="/ViewPharmacy" element={<ViewPharmacy />} />
          <Route path="/AddDoctor" element={<AddDoctor />} />
          <Route path="/ViewDoctor" element={<ViewDoctor />} />
          <Route path="/AddCity" element={<AddCity />} />
          <Route path="/ViewCity" element={<ViewCity />} />
          <Route path="/AddDistrict" element={<AddDistrict />} />
          <Route path="/ViewDistrict" element={<ViewDistrict />} />
          <Route path="/AddState" element={<AddState />} />
          <Route path="/ViewState" element={<ViewState />} />
          <Route path="/ViewUser" element={<ViewUser />} />
          <Route path="/ViewComplaint" element={<ViewComplaint />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
        </Route>
      </Route>
      <Route element={<ProtectedDoctorRoutes />}>
        <Route path="/" element={<HomeStudent />} />
        <Route path="/CustHome" element={<Home />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Product" element={<ProductInfo />} />
        <Route path="/ProductList" element={<ProductList />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Checkout" element={<Checkout />} />
      </Route>


    </Routes>
  );
}

export default MainRoutes;

// rgb(139 92 246 /1)
